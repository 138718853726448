<template>
  <div class="world calculator-page">
    <div class="wrapper-title">
      <div class="container">
        <button class="text-wilmsLightBlue font-100 block" @click="backToOverview">&larr; {{ $t('Terug naar offerte') }}</button>
        <h1 v-if="quoteNumber">{{ $t('Offerte') + ' ' + quoteNumber }}: {{ $t('Configuratie rolluiken') }}</h1>
        <div class="flex"></div>
      </div>
    </div>

    <div class="calculator-wrapper">
      <div class="container">
        <div class="steps-wrapper">
          <div class="step-buttons">
            <h3>{{ $t('Configuratie') }}</h3>
            <CalculatorButton :activeStep="activeStep" :label=" $t('Type') " :type="'type'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateType())" :label=" $t('Motorisatie') " :type="'motor'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateMotor())" :label=" $t('Configuraties') " :type="'size'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateWidth())" :label=" $t('Kleur kast') " :type="'cabinet_colors'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateCabinetType())" :label=" $t('Kleur lamellen') " :type="'slat_colors'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateColors())" :label=" $t('Opties') " :type="'options'" @setStep="setStep"/>
          </div>

          <div class="step-contents">
            <div v-if="activeStep === 'type'">
              <h2>{{ $t('Naar welk type rolluik ben je op zoek?') }}</h2>

              <div class="types-wrapper">
                <h3>{{ $t('PVC LAMELLEN') }}</h3>
                <div class="types types-shutter">
                  <button v-for="type in wpTypes" :key="type.key" :class="{'active': selectedOptions.type === type.key }" class="type" @click="selectType(type.key)" @dblclick="setStep('motor')">
                    <img v-if="selectedOptions.type === type.key" alt="" class="icon-check" src="@/assets/img/check_solid.svg" width="23">
                    <div class="image">
                      <img :alt="type.label" :src="type.image">
                    </div>
                    <h3>{{ type.label }}</h3>
                  </button>
                </div>

                <h3>{{ $t('ALUMINIUM LAMELLEN') }}</h3>
                <div class="types types-shutter">
                  <button v-for="type in waTypes" :key="type.key" :class="{'active': selectedOptions.type === type.key }" class="type" @click="selectType(type.key)" @dblclick="setStep('motor')">
                    <img v-if="selectedOptions.type === type.key" alt="" class="icon-check" src="@/assets/img/check_solid.svg" width="23">
                    <div class="image">
                      <img :alt="type.label" :src="type.image">
                    </div>
                    <h3>{{ type.label }}</h3>
                    <div class="description">{{ type.description }}</div>
                  </button>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!selectedOptions.type" class="btn" @click="setStep('motor')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'motor'">
              <h2>{{ $t('Selecteer het type motor') }}</h2>
              <formulate-input v-model="selectedOptions.engineConnection" :label="$t('Motor')" :options="enginesConnections" :placeholder="$t('Selecteer type motor')" type="select" @change="setEngine"></formulate-input>
              <div class="buttons">
                <button :disabled="!(validateMotor())" class="btn" @click="setStep('size')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'size'">
              <h2>{{ $t('Selecteer jouw breedte en hoogte') }}</h2>
              <formulate-form ref="widthForm" name="widthForm">
                <formulate-input v-model="selectedOptions.measurement_style" :options="{'default': $t('Standaard'), 'cabinet': $t('Kast'), 'cabinet_rails': $t('Kast + geleiders')}" type="radio" @input="setWidths"></formulate-input>

                <div class="shutter-configs">
                  <div v-for="(shutter, i) in selectedOptions.shutters" :key="'shutter-' + i" class="shutter-config">
                    <formulate-input v-model="shutter.name" :label="$t('Naam')"/>

                    <div v-if="shutter.width && !shutter.maxHeight" class="error-message">
                      <span v-if="selectedOptions.measurement_style === 'outside'">
                        {{ $t('Let op: opgegeven breedte is te breed. Hou rekening met de geleiders (+ 110mm).') }}
                      </span> <span v-else>
                        {{ $t('Let op: opgegeven breedte is te breed.') }}
                      </span>
                    </div>

                    <div v-if="shutter.height && !shutter.price" class="error-message">
                      {{ $t('Er kon geen prijs gevonden worden voor de opgegeven breedte en hoogte.') }}
                    </div>

                    <div class="width-height">
                      <formulate-input
                        v-model="shutter.width"
                        :help="$t('De minimale breedte is ') + minWidth + 'mm. ' + $t('De maximale breedte is ') + maxWidth + 'mm.'"
                        :label="$t('Breedte (mm)')"
                        :max="maxWidth"
                        :min="minWidth"
                        :placeholder="$t('Vul een breedte in')"
                        :validation="'required|number|between:' + (minWidth - 1) + ',' + (maxWidth + 1) + ',value'"
                        :validationMessages="{ between: $t('Breedte moet tussen ' + minWidth + ' en ' + maxWidth) }"
                        type="number"
                        @input="setWidth(shutter)"
                      ></formulate-input>

                      <formulate-input
                        v-model="shutter.height"
                        :help="shutter.maxHeight > 0 ? $t('De maximale hoogte is ') + shutter.maxHeight + 'mm.' : ''"
                        :label="$t('Hoogte (mm)')"
                        :max="shutter.maxHeight"
                        :placeholder="$t('Vul een hoogte in')"
                        :validation="'required|number|max:' + (shutter.maxHeight)"
                        :validationMessages="{max: shutter.maxHeight > 0 ? $t('Hoogte (mm) moet kleiner of gelijk zijn aan ' + shutter.maxHeight) : ''}"
                        type="number"
                        @input="fetchPrice(shutter)"
                      ></formulate-input>
                    </div>
                  </div>
                </div>

                <button class="btn btn-add" type="button" @click="addShutter">{{ $t('Rolluik toevoegen') }}</button>

                <div class="buttons">
                  <button :disabled="!(validateWidth())" class="btn" @click="setStep('cabinet_colors')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-if="activeStep === 'cabinet_colors'">
              <h2>{{ $t('Selecteer de kastkleur') }}</h2>

              <formulate-input v-if="selectedOptions.cabinet_type" v-model="selectedOptions.color_alternative" :label="$t('Alternatieve RAL-kleur')" type="checkbox" @change="setAlternativeColor"></formulate-input>

              <template v-if="selectedOptions.cabinet_type === 'cabinet_square'">
                <h3>{{ $t('Kast 45° - 9mm') }}</h3>
                <div class="colors">
                  <div v-for="color in cabinetSquareColors" :key="'cabinet-square-' + color.ral" :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_square' }" class="color-wrapper" @click="selectColor(color)" @dblclick="setStep('options')">
                    <Icon v-if="selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_square'" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                    <div :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_square' }" :style="{ 'background-color': ralColors[color.ral].color.hex }" class="color"></div>
                    <span>{{ color.name }}</span>
                  </div>
                </div>
              </template>

              <template v-if="selectedOptions.cabinet_type === 'cabinet_round'">
                <h3>{{ $t('Kast Rond - 9mm') }}</h3>
                <div class="colors">
                  <div v-for="color in cabinetRoundColors" :key="'cabinet-round-' + color.ral" :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_round' }" class="color-wrapper" @click="selectColor(color)" @dblclick="setStep('options')">
                    <Icon v-if="selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_round'" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                    <div :class="{ 'active' : selectedOptions.color && selectedOptions.color.ral === color.ral && selectedOptions.color.component === 'cabinet_round' }" :style="{ 'background-color': ralColors[color.ral].color.hex }" class="color"></div>
                    <span>{{ color.name }}</span>
                  </div>
                </div>
              </template>

              <div class="cabinet-types">
                <button :class="{ 'active': selectedOptions.cabinet_type === 'cabinet_square' }" class="cabinet-type" @click="setCabinetType('cabinet_square')">
                  <img v-if="selectedOptions.cabinet_type === 'cabinet_square'" alt="" class="icon-check" src="@/assets/img/check_solid.svg" width="23">
                  <span class="image">
                    <img alt="" src="@/assets/img/calculator/cabinets/square.svg">
                  </span> {{ $t('Kast 45°') }}
                </button>

                <button :class="{ 'active': selectedOptions.cabinet_type === 'cabinet_round' }" class="cabinet-type" @click="setCabinetType('cabinet_round')">
                  <img v-if="selectedOptions.cabinet_type === 'cabinet_round'" alt="" class="icon-check" src="@/assets/img/check_solid.svg" width="23">
                  <span class="image">
                    <img alt="" src="@/assets/img/calculator/cabinets/round.svg">
                  </span> {{ $t('Kast rond') }}
                </button>
              </div>

              <div class="buttons">
                <button :disabled="!(validateCabinetType())" class="btn" @click="setStep('slat_colors')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'slat_colors'">
              <h2>{{ $t('Selecteer de kleur van de lamellen') }}</h2>

              <div class="colors">
                <div v-for="color in slatColors" :key="'slat-' + color.id" :class="{ 'active' : selectedOptions.slat_color && selectedOptions.slat_color.ral === color.ral }" class="color-wrapper" @click="selectSlatColor(color)" @dblclick="setStep('options')">
                  <Icon v-if="selectedOptions.slat_color && selectedOptions.slat_color.ral === color.ral" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                  <div :class="{ 'active' : selectedOptions.slat_color && selectedOptions.slat_color.ral === color.ral }" :style="{ 'background-color': ralColors[color.ral] && ralColors[color.ral].color.hex }" class="color"></div>
                  <span>{{ color.name }}</span>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!(validateColors())" class="btn" @click="setStep('options')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-if="activeStep === 'options'">
              <h2>{{ $t('Opties') }}</h2>
              <div class="help">{{ $t('Selecteer de opties die je wilt toevoegen') }}</div>
              <v-select ref="tester" v-model="selectedOptions.optionsSelected" :options="options" label="name" multiple @option:selecting="selectOption" @option:deselected="deselectOption"></v-select>

              <div class="option-inputs">
                <div v-for="option in selectedOptions.options" :key="option.id">
                  <div class="icon" @click="deselectOption(option)">
                    <Icon color="#cf5353" icon="clarity:remove-solid"/>
                  </div>
                  <formulate-input v-model="option.amount" :label="$t(option.name)" class="with-suffix" type="number">
                    <template #suffix>
                      <span class="suffix">{{ option.unit_type === 'piece' ? $t('aantal stuks') : $t('aantal lopende meter') }}</span>
                    </template>
                  </formulate-input>
                </div>
              </div>

              <div class="buttons">
                <button class="btn" @click="saveShutters">{{ $t('Configuraties opslaan en terugkeren naar offerte') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalculatorButton from '@/components/world/CalculatorButton'
import { Icon } from '@iconify/vue2'
import { ApiService } from '@/services/admin/api.service'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { DateTime } from 'luxon'
import calculators from '@/calculators'

export default {
  name: 'Calculator',
  mixins: [
    calculators
  ],
  components: {
    Icon,
    CalculatorButton,
    vSelect
  },
  data () {
    return {
      price: null,
      quoteNumber: null,
      calculatorData: null,
      activeStep: 'type',
      cabinetRoundColors: [],
      cabinetSquareColors: [],
      slatColors: [],
      minWidth: null,
      maxWidth: null,
      wpTypes: [
        {
          key: 'wp37',
          label: 'WP37',
          description: '8,7mm x 37mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        }
      ],
      waTypes: [
        {
          key: 'wa39',
          label: 'WA39',
          description: '8,5mm x 39mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        },
        {
          key: 'wa40',
          label: 'WA40',
          description: '9mm x 40mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        },
        {
          key: 'wa40h',
          label: 'WA40H',
          description: '9mm x 40mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        },
        {
          key: 'wa55',
          label: 'WA55',
          description: '14mm x 55mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        },
        {
          key: 'wa55h',
          label: 'WA55H',
          description: '14mm x 55mm',
          image: require('@/assets/img/calculator/types/wp37.svg')
        }
      ],
      engineOptions: [],
      options: [],
      connections: {},
      enginesConnections: {},
      selectedOptions: {
        name: '',
        engineConnection: null,
        type: 'wa39',
        engine: null,
        measurement_style: '',
        cabinet_type: '',
        color: null,
        color_alternative: false,
        slat_color: null,
        options: [],
        optionsData: [],
        optionsSelected: [],
        shutters: [
          {
            width: null,
            height: null,
            name: '',
            price: 0,
            maxHeight: null
          }
        ]
      },
      datetime: DateTime
    }
  },
  async mounted () {
    await this.setDealer()
    await this.fetchQuoteNumber()
    await this.selectType(this.selectedOptions.type, false)
  },
  methods: {
    async fetchQuoteNumber () {
      await ApiService.fetchQuoteNumber(this.$route.params.quoteId).then(res => {
        this.quoteNumber = res.data
      })
    },
    async fetchShutter () {
      if (this.$route.params.quoteId && this.$route.params.id) {
        await ApiService.fetchShutter(this.$route.params.id).then(async res => {
          this.calculatorData = res.data

          if (res.status === 200) {
            await this.normalizeCalculatorData(this.calculatorData)
          }
        })
      }
    },
    async addShutter () {
      this.selectedOptions.shutters.push({
        width: null,
        height: null,
        name: '',
        price: 0,
        maxHeight: 0
      })
    },
    async saveShutters () {
      for (const shutter of this.selectedOptions.shutters) {
        const shutterData = { ...this.selectedOptions, ...shutter }
        shutterData.quote_id = this.$route.params.quoteId

        const res = await ApiService.createShutter(shutterData)

        if (res.status === 200) {
          const shutterDb = res.data
          let colorData = null

          if (shutterData.color) {
            colorData = {
              component: shutterData.color.component,
              color_id: shutterData.color.id
            }
          } else if (this.selectedOptions.color_alternative) {
            colorData = {
              component: this.selectedOptions.cabinet_type
            }
          }

          await ApiService.createShutterColor(shutterDb.id, colorData)
          await ApiService.createShutterColor(shutterDb.id, {
            color_id: shutterData.slat_color.id,
            component: shutterData.slat_color.component
          })

          for (const option of shutterData.options) {
            const obj = {
              option_id: option.id,
              amount: option.amount
            }
            await ApiService.createShutterOption(shutterDb.id, obj)
          }
        }
      }

      await this.$router.push({
        name: 'ww.quotes.detail',
        params: { id: this.$route.params.quoteId },
        query: { tab: 'overview' }
      })
    },
    async fetchPrice (shutter) {
      if (shutter.width && shutter.height) {
        await ApiService.fetchShutterPrice(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, shutter.width, shutter.height).then(res => {
          shutter.price = res.data
        })
      }
    },
    backToOverview () {
      this.setStep(this.activeStep)
      this.$router.push({ name: 'ww.quotes.detail', params: { id: this.$route.params.quoteId } })
    },
    async normalizeCalculatorData (data) {
      this.selectedOptions = { ...this.selectedOptions, ...data }
      // this.selectedOptions.ral = parseInt(this.selectedOptions.ral)
      this.selectedOptions.options = data.options
      this.selectedOptions.optionsSelected = data.options.map(option => {
        option.internal_name = option.name
        option.name = this.$t(option.name)
        return option
      })

      if (this.selectedOptions.engine && this.selectedOptions.connection) {
        this.selectedOptions.engineConnection = this.selectedOptions.engine + '_' + this.selectedOptions.connection
      }

      this.selectedOptions.color = data.colors.find(color => color.component === 'cabinet_square' || color.component === 'cabinet_round')
      this.selectedOptions.slat_color = data.colors.find(color => color.component === 'slats')
    },
    validateType () {
      if (this.selectedOptions.type) {
        return true
      }

      return false
    },
    validateMotor () {
      if (this.validateType() && this.selectedOptions.engine) {
        return true
      }

      return false
    },
    validateWidth () {
      if (!this.validateMotor()) {
        return false
      }

      for (const shutter of this.selectedOptions.shutters) {
        if (!(shutter.width >= this.minWidth && shutter.width <= this.maxWidth)) {
          return false
        }

        if (shutter.height > shutter.maxHeight) {
          return false
        }

        if (!shutter.price) {
          return false
        }
      }

      return true
    },
    validateCabinetType () {
      if (this.validateWidth() && this.selectedOptions.cabinet_type && (this.selectedOptions.color || this.selectedOptions.color_alternative)) {
        return true
      }

      return false
    },
    validateColors () {
      if (this.validateCabinetType() && this.selectedOptions.slat_color) {
        return true
      }

      return false
    },
    async setStep (step) {
      this.calculatorData = { ...this.calculatorData, ...this.selectedOptions }
      await this.setEngine()
      this.activeStep = step
    },
    async selectType (type, resetEngineConnection = true) {
      this.selectedOptions.type = type

      if (resetEngineConnection) {
        this.selectedOptions.engine = null
        this.selectedOptions.connection = null
      }

      await this.fetchCalculatorEnginesConnections()
      // await this.setWidth()
    },
    async fetchCalculatorEnginesConnections () {
      await ApiService.fetchShutterEnginesConnections(this.selectedOptions.type).then(async res => {
        this.enginesConnections = {}

        res.data.forEach(engineConnection => {
          this.enginesConnections[engineConnection.engine + '_' + engineConnection.connection] = this.$t(engineConnection.engine) + ' - ' + this.$t(engineConnection.connection)
        })

        if (Object.keys(this.enginesConnections).length === 1) {
          this.selectedOptions.engineConnection = Object.keys(this.enginesConnections)[0]
          await this.setEngine()
        }
      })
    },
    async setEngine () {
      if (this.selectedOptions.engineConnection) {
        const engineConnection = this.selectedOptions.engineConnection.split('_')
        this.selectedOptions.engine = engineConnection[0]
        this.selectedOptions.connection = engineConnection[1]
      }

      await this.fetchCalculatorInfo()
      await ApiService.fetchShutterOptions(this.selectedOptions.type).then(res => {
        this.options = res.data.map(option => {
          option.internal_name = option.name
          option.name = this.$t(option.name)
          return option
        }).sort((a, b) => a.name > b.name ? 1 : -1)
      })
    },
    async setCabinetType (type) {
      this.selectedOptions.cabinet_type = type

      this.selectedOptions.color = null
      this.selectedOptions.slat_color = null
      this.selectedOptions.color_alternative = false
    },
    async setAlternativeColor () {
      this.selectedOptions.color = null
    },
    async selectColor (color) {
      this.selectedOptions.color_alternative = false
      this.selectedOptions.color = color
    },
    async selectSlatColor (color) {
      this.selectedOptions.slat_color = color
    },
    async fetchCalculatorInfo () {
      await ApiService.fetchShutterInfo(this.selectedOptions.type, this.selectedOptions.engine).then((res) => {
        if (res.data) {
          this.minWidth = res.data.width[0]
          this.maxWidth = res.data.width[1]
          this.cabinetSquareColors = res.data.colors.cabinet_square
          this.cabinetRoundColors = res.data.colors.cabinet_round
          this.slatColors = res.data.colors.slats
        }
      })
    },
    async setWidth (shutter) {
      if (this.validateMotor() && shutter.width) {
        await ApiService.fetchShutterHeight(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, shutter.width, this.selectedOptions.measurement_style).then(res => {
          shutter.maxHeight = res.data || 0
        })
      }
    },
    async setWidths () {
      if (this.validateMotor()) {
        for (const shutter of this.selectedOptions.shutters) {
          if (shutter.width) {
            await ApiService.fetchShutterHeight(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, shutter.width, this.selectedOptions.measurement_style).then(res => {
              shutter.maxHeight = res.data || 0
            })
          }
        }
      }
    },
    /** options methods **/
    async selectOption (option) {
      this.selectedOptions.options.push(
        {
          id: option.id,
          name: option.internal_name,
          amount: 1,
          unit_type: option.unit_type
        }
      )
    },
    deselectOption (option) {
      this.selectedOptions.optionsSelected.splice(this.selectedOptions.optionsSelected.findIndex(opt => opt.id === option.id), 1)
      this.selectedOptions.options.splice(this.selectedOptions.options.findIndex(opt => opt.id === option.id), 1)
    },
    findOptionByInternalName (internalName) {
      const foundOption = this.options.find(optionObj => optionObj.internal_name === internalName)

      if (foundOption) {
        const existingOption = this.selectedOptions.options.find(optionObj => optionObj.option_id === foundOption.id)

        if (!existingOption) {
          return {
            option_id: foundOption.id,
            amount: 0
          }
        }
      }

      return null
    }
  },
  computed: {
    ralColorsSorted () {
      if (this.ralSearch === '') {
        return []
      }

      const filteredRals = {}
      Object.keys(this.ralColors).forEach(ral => {
        if (ral.includes(this.ralSearch) || ('RAL' + ral).includes(this.ralSearch)) {
          filteredRals[ral] = this.ralColors[ral]
        }
      })
      return filteredRals
    }
  }
}
</script>
